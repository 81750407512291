.App {
  text-align: center;
}

.icons-wrapper {
  display: block;
  text-align: center;
}

.icons-content {
  display: inline-block;
  margin: 0 auto;
}

.game-icon {
  display: inline-block;
  height: 15vmin;
  float: left;
  margin: 1vmin;
  cursor: pointer;
}

.arcade {
  padding-top: 3vmin;
  height: 40vmin;
  pointer-events: none;
}

.heading {
  padding-top: 3vmin;
  height: 12vmin;
  line-height: 12vmin;
  font-size: 12vmin;
  font-weight: bolder;
}

.heading-tagline {
  height: 3vmin;
  line-height: 3vmin;
  font-size: 3vmin;
  font-weight: normal;
}

.heading-tagline a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px dotted white;
}

.tag-line {
  height: 7vmin;
  font-size: 4vmin;
  line-height: 7vmin;
  font-weight: bolder;
}
.App-header {
  background-color: cornflowerblue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
